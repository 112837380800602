import { isInViewport } from '../helpers/is-in-viewport';
class PathBlock {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.items = this.root.querySelectorAll('.path__item:not(.-title)');

        this.timer = null;
        this.isRunning = false;
        this.currIndex = 0;

        return true;
    }

    init() {
        this.setTimer();
    }

    setTimer() {
        if (!this.root) return;

        const rootItem = this.root;
        const scrollOffset = window.innerWidth < 992 ? window.innerHeight : 300;

        document.addEventListener('scroll', () => {
            if (isInViewport(rootItem, scrollOffset)) {
                this.animateItems();
            } else {
                this.resetItems();
            }
        });
    }

    animateItems() {
        if (!this.items) return;
        let counter = 0;
        const items = this.items;
        if (!this.isRunning) {
            this.isRunning = true;
            this.timer = setInterval(function () {
                if (counter < items.length) {
                    items[counter].classList.add('-active');
                    counter++;
                } else {
                    this.isRunning = false;
                    return;
                }
            }, 750);
        }
    }

    resetItems() {
        if (!this.items) return;
        if (this.isRunning) {
            clearInterval(this.timer);
            this.isRunning = false;
        } else {
            this.items.forEach(item => item.classList.remove('-active'));
        }
    }

    setActiveItem(item) {
        item.classList.add('-active');
    }
}

function initPathBlock() {
    const sections = document.querySelectorAll('[data-path-block]');
    if (!sections) return;

    sections.forEach(item => new PathBlock(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initPathBlock();
} else {
    document.addEventListener('DOMContentLoaded', initPathBlock);
}
